import React, { useEffect } from 'react'

import Navbar from './Navbar'
import Footer from './Footer'
import about from '../assets/about.jpg'

const AboutUs = ({ navSelected, setNavSelected }) => {
    useEffect(() => {
        setNavSelected('about')
    }, [])

    return (
        <>
            <Navbar navSelected={navSelected}/>
            <div className='h-fit min-h-[calc(100%-60px)] w-full grid grid-rows-1'>
                <div className='h-full max-h-[1024px] w-full grid grid-rows-2'>
                    <div className='relative h-full min-h-[300px] w-full p-[20px] flex items-center justify-center'>
                        <img src={about} alt="about" className='absolute top-0 left-0 h-full w-full object-cover' />
                        <div className='absolute top-0 left-0 h-full w-full bg-[rgba(255,255,255,0.4)]' style={{ zIndex: 1 }} />
                        <div className='z-10 max-w-[1024px]'>
                            <p className='text-black text-5xl font-bold text-center leading-normal sm:text-3xl'>What we do</p>
                            <p className='text-black text-lg font-medium text-center leading-normal sm:text-base'>We help you find better flight deals that are bought with Miles.</p>
                        </div>
                    </div>
                    <div className='h-full min-h-fit w-full p-[20px] flex items-center justify-center'>
                        <div className='z-10 max-w-[1024px] flex flex-col items-center justify-center gap-[20px]'>
                            <p className='text-black text-3xl font-bold text-center leading-normal sm:text-xl'>About FMF.flights</p>
                            <p className='text-black text-base font-normal text-justify leading-normal sm:text-sm'>FMF.flights introduces a way for you to search Business Class flights costing similar to the price of Economy Class using Miles Programs. We want to introduce you the potential of Miles Programs to get yourself free of conventional booking frustrations and embrace a new era of travel that maximizes your savings and comfort.</p>
                            <p className='text-black text-base font-normal text-justify leading-normal sm:text-sm'>Effortlessly find the flights that align with your plans. Whether you're embarking on a city-to-city adventure or prefer the flexibility of city-to-region travel, FMF caters to your wanderlust. Explore multiple options, and open the door to new journeys you never thought possible.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default AboutUs