import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HiMenu } from 'react-icons/hi'

import fmfLogo from '../assets/fmfLogo.png'

const Navbar = ({ navSelected }) => {
  const navigate = useNavigate()
  const [displayMenu, setDisplayMenu] = useState(false)

  return (
    <div className='sticky top-0 h-[60px] z-50 w-full bg-blue-600 flex flex-col items-center'>
      <div className='relative h-full w-full max-w-[1024px] py-[10px] px-[20px] flex items-center justify-between'>
        <div className='h-[40px] flex gap-[10px] items-center cursor-pointer' onClick={() => navigate('/')}>
          <img src={fmfLogo} alt="logo" className='h-[40px] w-[40px] bg-white rounded-lg' />
          <p className='text-white'>FMF.flights</p>
        </div>
        <div className='h-full flex items-center gap-x-[10px] xl:hidden'>
          <div className={`h-full flex items-center px-[10px] rounded-lg text-sm duration-200 ${(navSelected === 'home') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} hover:bg-white hover:text-black`} onClick={() => (navSelected !== 'home') && navigate('/')}>Home</div>
          <div className={`h-full flex items-center px-[10px] rounded-lg text-sm duration-200 ${(navSelected === 'search') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} hover:bg-white hover:text-black`} onClick={() => (navSelected !== 'search') && navigate('/search')}>Search</div>
          <div className={`h-full flex items-center px-[10px] rounded-lg text-sm duration-200 ${(navSelected === 'about') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} hover:bg-white hover:text-black`} onClick={() => (navSelected !== 'about') && navigate('/about')}>About</div>
          <div className={`h-full flex items-center px-[10px] rounded-lg text-sm duration-200 ${(navSelected === 'contact') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} hover:bg-white hover:text-black`} onClick={() => (navSelected !== 'contact') && navigate('/contact')}>Contact</div>
          <div className={`h-full flex items-center px-[10px] rounded-lg text-sm duration-200 ${(navSelected === 'policy') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} hover:bg-white hover:text-black`} onClick={() => (navSelected !== 'policy') && navigate('/policy')}>Privacy Policy</div>
          <div className={`h-full flex items-center px-[10px] rounded-lg text-sm duration-200 ${(navSelected === 'terms') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} hover:bg-white hover:text-black`} onClick={() => (navSelected !== 'terms') && navigate('/terms')}>Terms & Conditions</div>
        </div>
        <div className='h-fit w-fit cursor-pointer hidden xl:block' onClick={() => setDisplayMenu(p => !p)}>
          <HiMenu size={40} color={'#FFF'}/>
        </div>
        <div className={`absolute top-[100%] left-0 bg-blue-600 h-fit w-full grid-flow-row gap-[10px] p-[20px] hidden xl:grid duration-200 origin-top ${displayMenu ? 'scale-y-100' : 'scale-y-0'}`}>
          <div className={`h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg text-sm border border-[#FFF] ${(navSelected === 'home') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} duration-200 hover:bg-white hover:text-black`} onClick={() => {setDisplayMenu(p => !p); ((navSelected !== 'home') && navigate('/'));}}>Home</div>
          <div className={`h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg text-sm border border-[#FFF] ${(navSelected === 'search') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} duration-200 hover:bg-white hover:text-black`} onClick={() => {setDisplayMenu(p => !p); ((navSelected !== 'search') && navigate('/search'));}}>Search</div>
          <div className={`h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg text-sm border border-[#FFF] ${(navSelected === 'about') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} duration-200 hover:bg-white hover:text-black`} onClick={() => {setDisplayMenu(p => !p); ((navSelected !== 'about') && navigate('/about'));}}>About</div>
          <div className={`h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg text-sm border border-[#FFF] ${(navSelected === 'contact') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} duration-200 hover:bg-white hover:text-black`} onClick={() => {setDisplayMenu(p => !p); ((navSelected !== 'contact') && navigate('/contact'));}}>Contact</div>
          <div className={`h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg text-sm border border-[#FFF] ${(navSelected === 'policy') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} duration-200 hover:bg-white hover:text-black`} onClick={() => {setDisplayMenu(p => !p); ((navSelected !== 'policy') && navigate('/policy'));}}>Privacy Policy</div>
          <div className={`h-[40px] w-full flex items-center justify-center px-[10px] rounded-lg text-sm border border-[#FFF] ${(navSelected === 'terms') ? 'bg-white text-black cursor-default' : 'bg-transparent text-white cursor-pointer'} duration-200 hover:bg-white hover:text-black`} onClick={() => {setDisplayMenu(p => !p); ((navSelected !== 'terms') && navigate('/terms'));}}>Terms & Conditions</div>
        </div>
      </div>
    </div>
  )
}

export default Navbar