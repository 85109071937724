import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa'
import './App.css';

// import FirstTimeWelcome from './components/FirstTimeWelcome';
// import Welcome from './components/Welcome';
import AppDownload from './components/AppDownload';
import Home from './components/Home';
import Search from './components/Search';
import SearchIP from './components/SearchIP';
import Policy from './components/Policy';
import TermsConditions from './components/TermsConditions';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
// import SearchPro from './components/SearchPro';

function App() {
  const [display, setDisplay] = useState({ downloadApp: false })
  const [navSelected, setNavSelected] = useState('')

  return (
    <div className='fixed h-full w-full bg-white text-base text-black'>
      <div id='scrollDiv' className='relative h-full w-full overflow-y-scroll'>
        <div className='fixed z-50 bottom-[20px] right-[20px] h-[40px] w-[40px] bg-black flex items-center justify-center rounded-full cursor-pointer hover:opacity-50 duration-200' onClick={() => document.getElementById('scrollDiv').scrollTo({ top: 0, behavior: 'smooth' })}>
          <FaArrowUp size={24} color='#FFF'/>
        </div>
        <Router>
          <AppDownload display={display} setDisplay={setDisplay}/>
          <Routes>
            <Route exact path='/' element={<Home setNavSelected={setNavSelected}/>}/>
            <Route exact path='/search' element={<Search setDisplay={setDisplay} navSelected={navSelected} setNavSelected={setNavSelected}/>}/>
            <Route exact path='/policy' element={<Policy navSelected={navSelected} setNavSelected={setNavSelected}/>}/>
            <Route exact path='/terms' element={<TermsConditions navSelected={navSelected} setNavSelected={setNavSelected}/>}/>
            <Route exact path='/contact' element={<ContactUs navSelected={navSelected} setNavSelected={setNavSelected}/>}/>
            <Route exact path='/about' element={<AboutUs navSelected={navSelected} setNavSelected={setNavSelected}/>}/>
            <Route exact path='/Ip' element={<SearchIP setDisplay={setDisplay}/>}/>
            {/* <Route exact path='/searchpro' element={<SearchPro />}/> */}
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
