import React from 'react'

import fmfLogo from '../assets/fmfLogo.png'

const Footer = () => {
  return (
    <div className='w-full h-fit flex items-center justify-center border-t border-t-[#EEE]'>
      <div className='h-fit w-full max-w-[1700px] flex flex-col items-center py-[10px] px-[20px]'>
        <div className='h-fit flex items-center gap-2 text-sm'>
          <img src={fmfLogo} alt="logo" className='h-[30px] w-[30px]' />
          FMF.flights wishes you to find the perfect flight deal.
        </div>
        <p className='text-sm text-black text-center'>Copyright © 2023 FMF.flights. All rights reserved</p>
      </div>
    </div>
  )
}

export default Footer