import React, { useState, useEffect } from 'react'
import moment from 'moment/moment'
import { useNavigate, useLocation } from 'react-router-dom'
import { ImCancelCircle } from 'react-icons/im'
import { PiAirplaneInFlightBold, PiAirplaneTakeoffBold, PiAirplaneLandingBold } from 'react-icons/pi'
import { BsCalendarWeek, BsInfoCircleFill } from 'react-icons/bs'
import { MdAirlineSeatReclineExtra } from 'react-icons/md'
import { BiSolidPlaneAlt } from 'react-icons/bi'
import { FaExchangeAlt, FaSearch, FaPlane, FaPlaneArrival, FaPlaneDeparture, FaRegClock, FaMoneyBill, FaRegMoneyBillAlt } from 'react-icons/fa'

import useFetchSeats from '../hooks/useFetches/useFetchSeats'
import { getFlights } from '../hooks/functions/getFlights'
import { iso } from '../isoFinal.js'
import Navbar from './Navbar';
import Footer from './Footer';
import americanLogo from '../assets/sources/americanLogo.png'
import lifemilesLogo from '../assets/sources/lifemilesLogo.png'
import emiratesLogo from '../assets/sources/emiratesLogo.png'

const Search = ({ setDisplay, navSelected, setNavSelected }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [searchDetails, setSearchDetails] = useState({ origin: [], destination: [], from: moment().format('YYYY-MM-DD'), to: moment().add(3, 'days').format('YYYY-MM-DD'), source: 'all', class: 'all', originRegion: '', destinationRegion: '' })
    const [showAirportList, setShowAirportList] = useState({ origin: false, destination: false, originRegion: false, destinationRegion: true })
    const [airportList, setAirportList] = useState({ origin: [], destination: [], originRegion: [], destinationRegion: [], source: [] })
    const [finalAirportList, setFinalAirportList] = useState({ origin: [], destination: [] })
    const [formInput, setFormInput] = useState({ origin: '', destination: '' })
    const [searchResult, setSearchResult] = useState({ loading: false, data: [] })
    const [flightsPrice, setFlightsPrice] = useState({ loading: false, BUSINESS: 0 })
    const [filteredResult, setFilteredResult] = useState({ data: [], error: false, first: true })
    const [hoverInfo, setHoverInfo] = useState('')
    
    const fetch = useFetchSeats()
    const [ loading, data ] = fetch

    useEffect(() => {
        setNavSelected('search')
    }, [])

    useEffect(() => {
        const temp = location.state
        if (temp) {
            setFilteredResult(p => { return { ...p, first: false } })
            setShowAirportList(p => { return { ...p, destinationRegion: false } })
            setSearchDetails(p => { return { ...p, origin: temp.origin, destination: temp.destination, from: temp.from, to: temp.to } })
            getFlights({ searchDetails: { origin: temp.origin, destination: temp.destination, from: temp.from, to: temp.to, source: 'all', class: 'all', originRegion: '', destinationRegion: '' }, setSearchResult: setSearchResult });
        }
    }, [])

    // after getting regions data set airport list
    useEffect(() => {
        if (data.length > 0) {
            let originAirports = data.filter( (ele, ind) => ind === data.findIndex( elem => elem.OriginAirport === ele.OriginAirport && elem.OriginRegion === ele.OriginRegion))
            let destinationAirports = data.filter( (ele, ind) => ind === data.findIndex( elem => elem.DestinationAirport === ele.DestinationAirport && elem.DestinationRegion === ele.DestinationRegion))
            let regionsOrigin = Array.from(new Set(data.map(e => e.OriginRegion))).filter(f => f)
            let regionsDestination = Array.from(new Set(data.map(e => e.DestinationRegion))).filter(f => f)
            let sources = Array.from(new Set(data.map(e => e.Source)))
            let finalOriginAirports = []
            let finalDestinationAirports = []

            originAirports.map(e => {
                let filtered = iso.data.find(f => f.iata_code === e.OriginAirport)
                if (filtered) {
                    finalOriginAirports.push({ ...e, name: filtered.name ? filtered.name : '', municipality: filtered.municipality ? filtered.municipality : '' })
                }
            })
            destinationAirports.map(e => {
                let filtered = iso.data.find(f => f.iata_code === e.DestinationAirport)
                if (filtered) {
                    finalDestinationAirports.push({ ...e, name: filtered.name ? filtered.name : '', municipality: filtered.municipality ? filtered.municipality : '' })
                }
            })

            finalOriginAirports.sort((a, b) => a.OriginAirport.localeCompare(b.OriginAirport))
            finalDestinationAirports.sort((a, b) => a.DestinationAirport.localeCompare(b.DestinationAirport))

            setFinalAirportList({ origin: finalOriginAirports, destination: finalDestinationAirports })
            setAirportList({ origin: finalOriginAirports, destination: finalDestinationAirports, originRegion: regionsOrigin, destinationRegion: regionsDestination, source: sources })
        }
    }, [data])
    
    // filter origin airport
    const handleOriginInput = e => {
        e.preventDefault()
        setFormInput(p => { return { ...p, origin: e.target.value } })
        setShowAirportList(p => { return { ...p, origin: true } })
        const value = e.target.value.toLowerCase()
        let temp = (value === '') ? finalAirportList.origin : finalAirportList.origin.filter(f => f.OriginAirport.toLowerCase().includes(value) || f.name.toLowerCase().includes(value) || f.municipality.toLowerCase().includes(value))
        setAirportList(p => { return { ...p, origin: temp.sort((a, b) => a.OriginAirport.localeCompare(b.OriginAirport)) } })
    }

    // filter all airports of the selected origin region
    const handleOriginSelect = e => {
        e.preventDefault()
        const value = e.target.value
        const filtered = data.filter(e => e.OriginRegion === value).map(e => e.OriginAirport)
        let dupRemoved = Array.from(new Set(filtered))
        setSearchDetails(p => { return { ...p, origin: dupRemoved, originRegion: value } })
    }

    // on clicking origin airport
    const handleOriginList = e => {
        let checkFind = searchDetails.origin.find(f => f === e.OriginAirport)

        if (checkFind) {
            let temp = [...searchDetails.origin]
            let index = temp.indexOf(e.OriginAirport)
            temp.splice(index, 1)
            setSearchDetails(p => { return { ...p, origin: temp } })
        } else {
            let temp = [...searchDetails.origin]
            temp.push(e.OriginAirport)
            setSearchDetails(p => { return { ...p, origin: temp } })
        }

        setFormInput(p => { return { ...p, origin: '' } })
        setShowAirportList(p => { return { ...p, origin: false } });
        setAirportList(p => { return { ...p, origin: finalAirportList.origin.sort((a, b) => a.OriginAirport.localeCompare(b.OriginAirport)) } })
    }

    // remove selected from origin list
    const handleOriginDelete = e => {
        let temp = [...searchDetails.origin]
        let index = temp.indexOf(e)
        temp.splice(index, 1)
        setSearchDetails(p => { return { ...p, origin: temp } })
    }

    // filter destination airport
    const handleDestinationInput = e => {
        e.preventDefault()
        setFormInput(p => { return { ...p, destination: e.target.value } })
        setShowAirportList(p => { return { ...p, destination: true } })
        const value = e.target.value.toLowerCase()
        let temp = (value === '') ? finalAirportList.destination : finalAirportList.destination.filter(f => f.DestinationAirport.toLowerCase().includes(value) || f.name.toLowerCase().includes(value) || f.municipality.toLowerCase().includes(value))
        setAirportList(p => { return { ...p, destination: temp.sort((a, b) => a.DestinationAirport.localeCompare(b.DestinationAirport)) } })
    }

    // filter all airports of the selected destination region
    const handleDestinationSelect = e => {
        e.preventDefault()
        const value = e.target.value
        const filtered = data.filter(e => e.DestinationRegion === value).map(e => e.DestinationAirport)
        let dupRemoved = Array.from(new Set(filtered))
        setSearchDetails(p => { return { ...p, destination: dupRemoved, destinationRegion: value } })
    }

    // on clicking destination airport
    const handleDestinationList = e => {
        let checkFind = searchDetails.destination.find(f => f === e.DestinationAirport)

        if (checkFind) {
            let temp = [...searchDetails.destination]
            let index = temp.indexOf(e.DestinationAirport)
            temp.splice(index, 1)
            setSearchDetails(p => { return { ...p, destination: temp } })
        } else {
            let temp = [...searchDetails.destination]
            temp.push(e.DestinationAirport)
            setSearchDetails(p => { return { ...p, destination: temp } })
        }

        setFormInput(p => { return { ...p, destination: '' } })
        setShowAirportList(p => { return { ...p, destination: false } });
        setAirportList(p => { return { ...p, destination: finalAirportList.destination.sort((a, b) => a.DestinationAirport.localeCompare(b.DestinationAirport)) } })
    }

    // remove selected from destination list
    const handleDestinationDelete = e => {
        let temp = [...searchDetails.destination]
        let index = temp.indexOf(e)
        temp.splice(index, 1)
        setSearchDetails(p => { return { ...p, destination: temp } })
    }

    // exchange origin and destination
    const handleExchange = () => {
        const newBoolean = { origin: showAirportList.destination, destination: showAirportList.origin, originRegion: showAirportList.destinationRegion, destinationRegion: showAirportList.originRegion }
        const newDetails = { ...searchDetails, origin: searchDetails.destination, destination: searchDetails.origin, originRegion: searchDetails.destinationRegion, destinationRegion: searchDetails.originRegion }

        setShowAirportList(newBoolean);
        setSearchDetails(newDetails);
    }

    // search button
    const handleSearchButton = () => {
        setShowAirportList(p => { return { ...p, origin: false, destination: false }})
        setFilteredResult(p => { return { ...p, first: false }})
        if (searchDetails.origin.length === 0 || searchDetails.destination.length === 0) {
            window.alert('Please provide all the fields to start your search.')
        } else {
            getFlights({ searchDetails: searchDetails, setSearchResult: setSearchResult });
        }
    }

    // display economy class table cells
    const EconomyComponent = ({detail}) => {
        const [showDetail, setShowDetail] = useState(false)

        return (
            <div className='bg-green-600 w-fit px-[5px] rounded-md text-white' onMouseOver={() => setShowDetail(true)} onMouseOut={() => setShowDetail(false)}>
                {showDetail ? (
                    <div className='p-[5px]'>
                        <p>Cost: <span className='font-semibold bg-white text-green-600 rounded-md px-[5px]'>{detail.YMileageCost ? (detail.YMileageCost*1).toLocaleString() : '-'} pts</span></p>
                        <p>Estimated Cost {'(Using Miles)'}: <span className='font-semibold bg-white text-green-600 rounded-md px-[5px]'>${detail.YMileageCost ? (detail.YMileageCost*0.03).toLocaleString() : '-'}</span></p>
                        <p>Air: <span className='font-semibold bg-white text-green-600 rounded-md px-[5px]'>{detail?.YAirlines}</span></p>
                        <p>Direct: <span className={`font-semibold ${detail.YDirect ? 'bg-blue-600' : 'bg-[#FF0000]'} text-white rounded-md px-[5px]`}>{detail.YDirect ? 'YES' : 'NO'}</span></p>
                        {Number(detail?.YRemainingSeats) === 0 ? (<></>) : (
                            <p>Seats: <span className='font-semibold bg-white text-green-600 rounded-md px-[5px]'>{detail?.YRemainingSeats}</span></p>
                        ) }
                    </div>
                ) : (<>{detail.YMileageCost ? (detail.YMileageCost*1).toLocaleString()+'pts / $'+(detail.YMileageCost*0.03).toLocaleString() : '-'}</>)}
            </div>
        )
    }

    // display business class table cells
    const BusinessComponent = ({detail}) => {
        const [showDetail, setShowDetail] = useState(false)

        return (
            <div className='bg-purple-600 w-fit px-[5px] rounded-md text-white' onMouseOver={() => setShowDetail(true)} onMouseOut={() => setShowDetail(false)}>
                {showDetail ? (
                    <div className='p-[5px]'>
                        <p>Cost: <span className='font-semibold bg-white text-purple-600 rounded-md px-[5px]'>{detail.JMileageCost ? (detail.JMileageCost*1).toLocaleString() : '-'} pts</span></p>
                        <p>Estimated Cost {'(Using Miles)'}: <span className='font-semibold bg-white text-purple-600 rounded-md px-[5px]'>${detail.JMileageCost ? (detail.JMileageCost*0.03).toLocaleString() : '-'}</span></p>
                        <p>Air: <span className='font-semibold bg-white text-purple-600 rounded-md px-[5px]'>{detail?.JAirlines}</span></p>
                        <p>Direct: <span className={`font-semibold ${detail.JDirect ? 'bg-blue-600' : 'bg-[#FF0000]'} text-white rounded-md px-[5px]`}>{detail.JDirect ? 'YES' : 'NO'}</span></p>
                        {Number(detail?.JRemainingSeats) === 0 ? (<></>) : (
                            <p>Seats: <span className='font-semibold bg-white text-purple-600 rounded-md px-[5px]'>{detail?.JRemainingSeats}</span></p>
                        ) }
                    </div>
                ) : (<>{detail.JMileageCost ? (detail.JMileageCost*1).toLocaleString()+'pts / $'+(detail.JMileageCost*0.03).toLocaleString() : '-'}</>)}
            </div>
        )
    }

    // determine the booking url
    const handleBookURL = (e) => {
        if (e.Source === 'american') {
            window.open(`https://www.aa.com/booking/search?type=OneWay&searchType=Award&from=${searchDetails.origin[0]}&to=${searchDetails.destination[0]}&pax=1&cabin=&locale=en_US&nearbyAirports=true&depart=${moment(e.Date).format('YYYY-MM-DD')}&carriers=ALL&pos=US&adult=1`, '_blank')
        } else if (e.Source === 'lifemiles') {
            window.open('https://www.lifemiles.com/fly/find', '_blank')
        } else if (e.Source === 'emirates') {
            window.open('https://www.emirates.com/hk/english/', '_blank')
        }
    }

    const getSource = e => {
        let temp = <></>
        
        if (e === 'american') {
            temp = <><img src={americanLogo} alt='aa' style={{ height: 14, width: 14 }}/>&nbsp;American Airlines</>
        } else if (e === 'lifemiles') {
            temp = <><img src={lifemilesLogo} alt='aa' style={{ height: 14, width: 14 }}/>&nbsp;Avianca LifeMiles</>
        } else if (e === 'emirates') {
            temp = <><img src={emiratesLogo} alt='aa' style={{ height: 14, width: 14 }}/>&nbsp;Emirates Skywards</>
        }

        return temp
    }

    useEffect(() => {
        if (searchResult.data.length > 0) {
            const res = searchResult.data
            let final = []
            
            if (searchDetails.class === 'Economy') {
                let temp = res.filter(f => f?.YAvailable === true)
                if (searchDetails.source === 'all') {
                    final = temp
                } else {
                    final = temp.filter(f => f?.Source === searchDetails.source)
                }
            } else if (searchDetails.class === 'Business') {
                let temp = res.filter(f => f?.JAvailable === true)
                if (searchDetails.source === 'all') {
                    final = temp
                } else {
                    final = temp.filter(f => f?.Source === searchDetails.source)
                }
            } else {
                let temp = res.filter(f => (f?.JAvailable === true || f.YAvailable === true))
                if (searchDetails.source === 'all') {
                    final = temp
                } else {
                    final = temp.filter(f => f?.Source === searchDetails.source)
                }
            }

            setFilteredResult(p => { return { ...p, data: final.sort((a, b) => new Date(a.Date) - new Date(b.Date)), error: (final.length > 0) ? false : true }})
        } else {
            setFilteredResult(p => { return { ...p, data: [], error: true }})
        }
    }, [searchResult.data, searchDetails.source, searchDetails.class])
    
    return (
        <>
            <Navbar navSelected={navSelected}/>
            <div className='h-fit min-h-[calc(100%-60px)] flex justify-center'>
                <div className='h-fit w-full max-w-[1024px] px-[20px] py-[20px] flex flex-col items-center xxl:max-w-[1024px]'>
                    <div className='w-full flex items-center gap-x-[5px]'>
                        <p className='text-left text-2xl font-semibold'>Search Flights {'(LITE)'}</p>
                        <div className='h-[40px] bg-blue-600 hover:shadow-[0px_0px_10px] hover:shadow-blue-400 text-white font-semibold rounded-md px-[10px] flex items-center justify-center cursor-pointer' onClick={() => setDisplay(p => { return { ...p, downloadApp: true } })}>Go PRO</div>
                    </div>
                    <p className='w-full text-left mb-[20px]'>Find the best flight deals in a few clicks.</p>

                    {/* search bar */}
                    <div className={`h-fit w-full bg-[#FFF] p-[10px] mb-[20px] border-2 border-[#EEE] shadow-[0px_0px_5px_0px_#CCC] rounded-xl flex flex-col gap-[10px] ${(searchResult.loading || flightsPrice.loading || loading) ? 'pointer-events-none' : ''}`}>
                        <div className={`h-fit w-full grid grid-cols-1 gap-[10px] ${(searchResult.loading || flightsPrice.loading || loading) ? 'opacity-50' : 'opacity-100'}`}>
                            <div className='h-fit w-full flex gap-[10px] lg:flex-col lg:items-center'>
                                {/* origin */}
                                {showAirportList.originRegion ? (
                                    <div className='relative w-full h-fit min-w-[150px] border border-[#CCC] p-[5px] flex flex-col gap-y-[5px] rounded-lg'>
                                        <div className='text-sm text-[#555] font-medium flex items-center gap-x-1'>Origin Region <div className='bg-blue-600 text-white px-[5px] rounded-md cursor-pointer' onClick={() => {setSearchDetails(p => {return{...p, origin: [], originRegion: ''}}); setShowAirportList(p => {return{...p, originRegion: false}});}}>or Airport?</div><PiAirplaneTakeoffBold size={16} className='fill-blue-600'/> <BsInfoCircleFill size={20} className='fill-[#555] cursor-pointer' onMouseOver={() => setHoverInfo('origin')} onMouseOut={() => setHoverInfo('')}/></div>
                                        {(hoverInfo === 'origin') ? (
                                            <div className='w-fit bg-black text-white text-sm px-[5px] rounded-md'>Click "or Airport?" btn to search airports</div>
                                        ) : (<></>)}
                                        <div className='bg-white rounded-md'>
                                            <select className='h-[30px] flex-1 w-full min-w-[100px] bg-[#F5F5F5] text-sm border border-[#CCC] outline-none rounded-md' value={searchDetails.originRegion} onChange={e => handleOriginSelect(e)}>
                                                <option value='' disabled>--- Choose ---</option>
                                                {airportList.originRegion.map((e, i) => (
                                                    <option value={e} key={i}>{e}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='relative w-full h-fit min-w-[150px] border border-[#CCC] p-[5px] flex flex-col gap-y-[5px] rounded-lg'>
                                        <div className='text-sm text-[#555] font-medium flex items-center gap-x-1'>Origin Airport <div className='bg-blue-600 text-white px-[5px] rounded-md cursor-pointer' onClick={() => {setSearchDetails(p => {return{...p, origin: [], originRegion: ''}}); setShowAirportList(p => {return{...p, originRegion: true}});}}>or Region?</div><PiAirplaneTakeoffBold size={16} className='fill-blue-600'/> <BsInfoCircleFill size={20} className='fill-[#555] cursor-pointer' onMouseOver={() => setHoverInfo('origin')} onMouseOut={() => setHoverInfo('')}/></div>
                                        {(hoverInfo === 'origin') ? (
                                            <div className='w-fit bg-black text-white text-sm px-[5px] rounded-md'>Click "or Region?" btn to select region</div>
                                        ) : (<></>)}
                                        <div className='bg-white rounded-md flex flex-wrap gap-1'>
                                            {searchDetails.origin.map((e, i) => (
                                                <div key={i} onClick={() => handleOriginDelete(e)} className='h-[30px] border-2 border-[#555] rounded-md px-1 text-[#555] flex items-center gap-1 cursor-pointer'>
                                                    <ImCancelCircle size={16} className='fill-[#555]'/>{e}
                                                </div>
                                            ))}
                                            <input type="text"
                                                className='h-[30px] flex-1 w-full min-w-[100px] text-sm border-b-2 border-b-[#CCC] outline-none'
                                                placeholder='airport, city'
                                                onClick={e => { e.preventDefault(); setShowAirportList(p => { return { ...p, origin: !p.origin } }); }}
                                                value={formInput.origin}
                                                onChange={e => handleOriginInput(e)}/>
                                        </div>
                                        {showAirportList.origin ? (
                                            <div className='absolute z-10 top-full left-0 bg-white border-2 border-[#777] h-fit max-h-[200px] w-full p-[5px] rounded-md flex flex-col overflow-y-scroll'>
                                                {airportList.origin.map((e, i) => (
                                                    <div key={i} onClick={() => handleOriginList(e)} className={`w-full flex flex-row py-[5px] gap-[5px] border-b border-b-[#CCC] cursor-pointer hover:opacity-50 duration-200 ${searchDetails.origin.find(f => f === e.OriginAirport) ? 'bg-[#F5F5F5]' : 'bg-white'}`}>
                                                        <div className='w-[45px]'>
                                                            <div className='w-full border border-[#AAA] text-sm flex items-center justify-center rounded-md'>
                                                                {e.OriginAirport}
                                                            </div>
                                                        </div>
                                                        <div className='flex-1 text-sm'>{e.name}, {e.municipality}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : <></>}
                                    </div>
                                )}

                                {/* exchange */}
                                <div className='flex items-center'>
                                    <div className='bg-[#555] rounded-md p-[10px] flex flex-wrap gap-1 cursor-pointer' onClick={() => handleExchange()}>
                                        <FaExchangeAlt size={20} className='fill-white lg:rotate-90' />
                                    </div>
                                </div>

                                {/* destination */}
                                {showAirportList.destinationRegion ? (
                                    <div className='relative w-full h-fit min-w-[150px] border border-[#CCC] p-[5px] flex flex-col gap-y-[5px] rounded-lg'>
                                        <div className='text-sm text-[#555] font-medium flex items-center gap-x-1'>Destination Region <div className='bg-blue-600 text-white px-[5px] rounded-md cursor-pointer' onClick={() => {setSearchDetails(p => {return{...p, destination: [], destinationRegion: ''}}); setShowAirportList(p => {return{...p, destinationRegion: false}});}}>or Airport?</div><PiAirplaneLandingBold size={16} className='fill-blue-600'/> <BsInfoCircleFill size={20} className='fill-[#555] cursor-pointer' onMouseOver={() => setHoverInfo('destination')} onMouseOut={() => setHoverInfo('')}/></div>
                                        {(hoverInfo === 'destination') ? (
                                            <div className='w-fit bg-black text-white text-sm px-[5px] rounded-md'>Click "or Airport?" btn to search airports</div>
                                        ) : (<></>)}
                                        <div className='bg-white rounded-md'>
                                            <select className='h-[30px] flex-1 w-full min-w-[100px] bg-[#F5F5F5] text-sm border border-[#CCC] outline-none rounded-md' value={searchDetails.destinationRegion} onChange={e => handleDestinationSelect(e)}>
                                                <option value='' disabled>--- Choose ---</option>
                                                {airportList.destinationRegion.map((e, i) => (
                                                    <option value={e} key={i}>{e}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='relative w-full h-fit min-w-[150px] border border-[#CCC] p-[5px] flex flex-col gap-y-[5px] rounded-lg'>
                                        <div className='text-sm text-[#555] font-medium flex items-center gap-x-1'>Destination Airport <div className='bg-blue-600 text-white px-[5px] rounded-md cursor-pointer' onClick={() => {setSearchDetails(p => {return{...p, destination: [], destinationRegion: ''}}); setShowAirportList(p => {return{...p, destinationRegion: true}});}}>or Region?</div><PiAirplaneLandingBold size={16} className='fill-blue-600'/> <BsInfoCircleFill size={20} className='fill-[#555] cursor-pointer' onMouseOver={() => setHoverInfo('destination')} onMouseOut={() => setHoverInfo('')}/></div>
                                        {(hoverInfo === 'destination') ? (
                                            <div className='w-fit bg-black text-white text-sm px-[5px] rounded-md'>Click "or Region?" btn to select region</div>
                                        ) : (<></>)}
                                        <div className='bg-white rounded-md flex flex-wrap gap-1'>
                                            {searchDetails.destination.map((e, i) => (
                                                <div key={i} onClick={() => handleDestinationDelete(e)} className='h-[30px] border-2 border-[#555] rounded-md px-1 text-[#555] flex items-center gap-1 cursor-pointer'>
                                                    <ImCancelCircle size={16} className='fill-[#555]'/>{e}
                                                </div>
                                            ))}
                                            <input type="text"
                                                className='h-[30px] flex-1 w-full min-w-[100px] text-sm border-b-2 border-b-[#CCC] outline-none'
                                                placeholder='airport, city'
                                                onClick={e => { e.preventDefault(); setShowAirportList(p => { return { ...p, destination: !p.destination } }); }}
                                                value={formInput.destination}
                                                onChange={e => handleDestinationInput(e)}/>
                                        </div>
                                        {showAirportList.destination ? (
                                            <div className='absolute z-10 top-full left-0 bg-white border-2 border-[#777] h-fit max-h-[200px] w-full p-[5px] rounded-md flex flex-col overflow-y-scroll'>
                                                {airportList.destination.map((e, i) => (
                                                    <div key={i} onClick={() => handleDestinationList(e)} className={`w-full flex flex-row py-[5px] gap-[5px] border-b border-b-[#CCC] cursor-pointer hover:opacity-50 duration-200 ${searchDetails.destination.find(f => f === e.DestinationAirport) ? 'bg-[#F5F5F5]' : 'bg-white'}`}>
                                                        <div className='w-[45px]'>
                                                            <div className='w-full text-sm border border-[#AAA] flex items-center justify-center rounded-md'>
                                                                {e.DestinationAirport}
                                                            </div>
                                                        </div>
                                                        <div className='flex-1 text-sm'>{e.name}, {e.municipality}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : <></>}
                                    </div>
                                )}
                            </div>
                            
                            <div className='h-fit w-full flex'>
                                {/* date */}
                                <div className='relative flex-1 w-full h-fit border border-[#CCC] p-[5px] flex flex-col gap-y-[5px] rounded-lg'>
                                    <div className='text-sm text-[#555] font-medium flex items-center gap-x-1'>One way flights between <BsCalendarWeek size={16} className='fill-blue-600'/></div>
                                    <div className='bg-white rounded-md grid grid-cols-2 gap-[10px] sm:grid-cols-1'>
                                        <input type="date"
                                            className='h-[30px] flex-1 w-full min-w-[100px] bg-[#F5F5F5] text-sm border border-[#CCC] outline-none rounded-md'
                                            value={searchDetails.from}
                                            min={moment().format('YYYY-MM-DD')}
                                            onChange={e => {
                                                setShowAirportList(p => { return { ...p, origin: false, destination: false }})
                                                if (new Date(searchDetails.to) >= new Date(e.target.value)) {
                                                    setSearchDetails(p => { return { ...p, from: e.target.value } })
                                                } else (
                                                    setSearchDetails(p => { return { ...p, from: e.target.value, to: e.target.value } })
                                                )
                                            }}/>
                                        <input type="date"
                                            className='h-[30px] flex-1 w-full min-w-[100px] bg-[#F5F5F5] text-sm border border-[#CCC] outline-none rounded-md'
                                            value={searchDetails.to}
                                            min={searchDetails.from}
                                            onChange={e => {
                                                setShowAirportList(p => { return { ...p, origin: false, destination: false }})
                                                setSearchDetails(p => { return { ...p, to: e.target.value } })
                                            }}/>
                                    </div>
                                </div>

                            </div>

                            <div className='h-fit w-full grid grid-cols-2 gap-[10px] lg:grid-cols-1'>
                                {/* source */}
                                <div className='relative w-full h-fit min-w-[150px] border border-[#CCC] p-[5px] flex flex-col gap-[5px] rounded-lg'>
                                    <div className='text-sm text-[#555] font-medium flex items-center gap-x-1'>Source <PiAirplaneInFlightBold size={16} className='fill-blue-600'/> <BsInfoCircleFill size={20} className='fill-[#555] cursor-pointer' onMouseOver={() => setHoverInfo('source')} onMouseOut={() => setHoverInfo('')}/></div>
                                    {(hoverInfo === 'source') ? (
                                        <div className='w-fit bg-black text-white text-sm px-[5px] rounded-md'>Select to filter final result</div>
                                    ) : (<></>)}
                                    <div className='bg-white rounded-md flex flex-wrap gap-1'>
                                        <select className='h-[30px] flex-1 w-full min-w-[100px] bg-[#F5F5F5] text-sm border border-[#CCC] outline-none rounded-md' value={searchDetails.source} onChange={e => setSearchDetails(p => {return{...p, source: e.target.value}})}>
                                            <option value='all'>ALL</option>
                                            <option value='american'>American Airlines</option>
                                            <option value='lifemiles'>Avianca LifeMiles</option>
                                            <option value='emirates'>Emirates Skywards</option>
                                            <option disabled={true}>Aeromexico {'(PRO)'}</option>
                                            <option disabled={true}>Air Canada Aeroplan {'(PRO)'}</option>
                                            <option disabled={true}>Air France/KLM Flying Blue {'(PRO)'}</option>
                                            <option disabled={true}>Alaska Mileage Plan {'(PRO)'}</option>
                                            <option disabled={true}>Delta SkyMiles {'(PRO)'}</option>
                                            <option disabled={true}>Qantas Frequent Flyer {'(PRO)'}</option>
                                            <option disabled={true}>United MileagePlus {'(PRO)'}</option>
                                            <option disabled={true}>Virgin Atlantic {'(PRO)'}</option>
                                            <option disabled={true}>Virgin Australia Velocity {'(PRO)'}</option>
                                        </select>
                                    </div>
                                </div>

                                {/* class */}
                                <div className='relative w-full h-fit min-w-[150px] border border-[#CCC] p-[5px] flex flex-col gap-[5px] rounded-lg'>
                                    <div className='text-sm text-[#555] font-medium flex items-center gap-x-1'>Class <MdAirlineSeatReclineExtra size={16} className='fill-blue-600'/>  <BsInfoCircleFill size={20} className='fill-[#555] cursor-pointer' onMouseOver={() => setHoverInfo('class')} onMouseOut={() => setHoverInfo('')}/></div>
                                    {(hoverInfo === 'class') ? (
                                        <div className='w-fit bg-black text-white text-sm px-[5px] rounded-md'>Select to filter final result</div>
                                    ) : (<></>)}
                                    <div className='bg-white rounded-md flex flex-wrap gap-1'>
                                        <select className='h-[30px] flex-1 w-full min-w-[100px] bg-[#F5F5F5] text-sm border border-[#CCC] outline-none rounded-md' value={searchDetails.class} onChange={e => setSearchDetails(p => {return{...p, class: e.target.value}})}>
                                            <option value='all'>ALL</option>
                                            <option value='Economy'>Economy</option>
                                            <option value='Business'>Business</option>
                                        </select>
                                    </div>
                                </div>
                                
                            </div>

                        </div>

                        <div className='h-fit w-full flex items-center justify-center'>
                            {/* search button */}
                            <div onClick={() => handleSearchButton()} className={`${(searchResult.loading || flightsPrice.loading || loading) ? 'bg-[#AAA] pointer-events-none' : 'bg-blue-600 hover:shadow-[0px_0px_10px] hover:shadow-blue-400'} w-full max-w-[400px] h-[50px] flex items-center justify-center text-white font-semibold py-[10px] px-[20px] rounded-lg cursor-pointer duration-200`}>
                                {(searchResult.loading || flightsPrice.loading || loading) ? (
                                    <div className='h-[30px] w-[30px] border-[5px] border-white border-t-[5px] border-t-[#AAA] rounded-full animate-spin'></div>
                                ) : (
                                    <div className='flex items-center gap-[5px]'>
                                        <FaSearch size={16} color='#FFF'/>
                                        Find Flights
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* search result table */}
                    {searchResult.loading ? (
                        // loading animation for flights
                        <div className='h-fit w-full max-w-[1024px] border-2 border-[#EEE] rounded-xl p-[10px]'>
                            <p className='text-center'>Please wait. It might take 30-60 seconds to gather the best flight deals for you &#128522;</p>
                            <table className='w-full animate-pulse'>
                                <thead>
                                    <tr>
                                        <td className='h-[50px] py-[5px]'><div className='h-full w-full bg-[#AAA] rounded-l-full'/></td>
                                        <td className='h-[50px] py-[5px]'><div className='h-full w-full bg-[#AAA]'/></td>
                                        <td className='h-[50px] py-[5px]'><div className='h-full w-full bg-[#AAA]'/></td>
                                        <td className='h-[50px] py-[5px]'><div className='h-full w-full bg-[#AAA] rounded-r-full'/></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                    </tr>
                                    <tr>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                    </tr>
                                    <tr>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                    </tr>
                                    <tr>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                        <td className='h-[50px] py-[5px] px-[10px]'><div className='h-full w-full bg-[#CCC] rounded-full'/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        (filteredResult.data.length === 0) ? (
                            (filteredResult.error && !filteredResult.first) ? (
                                <div className='h-fit w-full border-2 border-[#FF7F7F] bg-[rgba(255,127,127,0.1)] rounded-xl p-[10px]'>
                                    <p className='text-center'>There are no flights scheduled or no seats available for the inputs you provided &#128532;</p>
                                    <p className='text-center'>Try a different date, airport or region &#128522;</p>    
                                </div>
                            ) : (
                                <div className='h-fit w-full border-2 border-[#EEE] rounded-xl p-[10px]'>
                                    <p className='text-center'>Your searches will be displayed here &#128522;</p>
                                </div>
                            )
                        ) : (
                            // <div className='h-fit w-full border-2 border-[#EEE] rounded-xl p-[10px] overflow-x-scroll no-scrollbar'>
                            //     <table className='w-full'>
                            //         <thead>
                            //             <tr className='border-b-2 border-b-[#EEE]'>
                            //                 <td className='p-[10px] font-semibold min-w-[150px]'>Date</td>
                            //                 <td className='p-[10px] font-semibold min-w-[150px]'>Last Seen</td>
                            //                 <td className='p-[10px] font-semibold min-w-[150px]'>Source</td>
                            //                 <td className='p-[10px] font-semibold min-w-[150px]'>Departs</td>
                            //                 <td className='p-[10px] font-semibold min-w-[150px]'>Arrives</td>
                            //                 {(searchDetails.class === 'all') ? (
                            //                     <>
                            //                         <td className='p-[10px] font-semibold min-w-[200px]'>Economy</td>
                            //                         <td className='p-[10px] font-semibold min-w-[200px]'>Business</td>
                            //                     </>
                            //                 ) : (
                            //                     <td className='p-[10px] font-semibold min-w-[250px]'>{searchDetails.class}</td>
                            //                 )}
                            //                 {(searchDetails.origin.length === 1 && searchDetails.destination.length === 1 && (searchDetails.class !== 'Economy')) ? (
                            //                     <td className='p-[10px] font-semibold min-w-[150px]'>Savings</td>
                            //                 ) : (<></>)}
                            //                 <td className='p-[10px] font-semibold min-w-[100px]'>Details</td>
                            //             </tr>
                            //         </thead>
                            //         <tbody>
                            //             {filteredResult.data.map((e, i) => (
                            //                 <tr key={i}>
                            //                     <td className='p-[10px] min-w-[150px]'>{e.Date}</td>
                            //                     <td className='p-[10px] min-w-[150px]'>{e.ComputedLastSeen}</td>
                            //                     <td className='p-[10px] min-w-[150px]'>{e.Source}</td>
                            //                     <td className='p-[10px] min-w-[150px]'>{e.Route.OriginAirport}</td>
                            //                     <td className='p-[10px] min-w-[150px]'>{e.Route.DestinationAirport}</td>
                            //                     {(searchDetails.class === 'all') ? (
                            //                         <>
                            //                             <td className='p-[10px] min-w-[250px]'>
                            //                                 {e.YAvailable ? (
                            //                                     <EconomyComponent detail={e}/>
                            //                                 ) : (
                            //                                     <div className='bg-[#888] w-fit px-[5px] rounded-md text-white'>Not Available</div>
                            //                                 )}
                            //                             </td>
                            //                             <td className='p-[10px] min-w-[250px]'>
                            //                                 {e.JAvailable ? (
                            //                                     <BusinessComponent detail={e}/>
                            //                                 ) : (
                            //                                     <div className='bg-[#888] w-fit px-[5px] rounded-md text-white'>Not Available</div>
                            //                                 )}
                            //                             </td>
                            //                         </>
                            //                     ) : (
                            //                         (searchDetails.class === 'Economy') ? (
                            //                             <td className='p-[10px] min-w-[250px]'>
                            //                                 {e.YAvailable ? (
                            //                                     <EconomyComponent detail={e}/>
                            //                                 ) : (
                            //                                     <div className='bg-[#888] w-fit px-[5px] rounded-md text-white'>Not Available</div>
                            //                                 )}
                            //                             </td>
                            //                         ) : (
                            //                             <td className='p-[10px] min-w-[250px]'>
                            //                                 {e.JAvailable ? (
                            //                                     <BusinessComponent detail={e}/>
                            //                                 ) : (
                            //                                     <div className='bg-[#888] w-fit px-[5px] rounded-md text-white'>Not Available</div>
                            //                                 )}
                            //                             </td>
                            //                         )
                            //                     )}
                            //                     {(searchDetails.origin.length === 1 && searchDetails.destination.length === 1 && (searchDetails.class !== 'Economy')) ? (
                            //                         <td className='p-[10px] min-w-[150px]'>
                            //                             <div className={`${(e.JAvailable && (flightsPrice.BUSINESS > 0)) ? 'bg-[#F2EBFD] text-purple-600' : 'bg-[#EEE] text-black'} w-fit font-medium rounded-md px-[5px] flex items-center gap-[5px]`}>
                            //                                 $ {(e.JAvailable && (flightsPrice.BUSINESS > 0)) ? (flightsPrice.BUSINESS - (e.JMileageCost*0.03)).toLocaleString() : '-'} {flightsPrice.loading ? (<div className='h-[20px] w-[20px] rounded-full border-2 border-black border-t-white animate-spin'></div>) : (<></>)}
                            //                             </div>
                            //                         </td>
                            //                     ) : (<></>)}
                            //                     <td className='p-[10px] min-w-[100px]'>
                            //                         <div className='bg-blue-600 text-white w-fit font-medium rounded-md px-[5px] shadow-md shadow-blue-500 flex items-center cursor-pointer hover:shadow-lg hover:shadow-blue-500 hover:scale-125 duration-300' onClick={() => handleBookURL(e.Source)}>
                            //                             CLAIM
                            //                         </div>
                            //                     </td>
                            //                 </tr>
                            //             ))}
                            //         </tbody>
                            //     </table>
                            // </div>

                            <div className='h-fit w-full flex flex-col items-center gap-[10px]'>
                                {filteredResult.data.map((e, i) => (
                                    <div key={i} className='h-fit w-full bg-white border-2 border-[#DDD] rounded-xl flex flex-col items-center p-[10px] gap-[10px]'>
                                        <div className='w-full flex flex-row flex-wrap items-center justify-center text-center gap-y-[10px] text-sm font-medium leading-normal'>{getSource(e.Source)}&nbsp;{'('}<BsCalendarWeek size={14} color='#000'/>&nbsp;{e.Date}{')'}</div>
                                        
                                        <div className='w-full flex flex-row items-center'>
                                            <div className='flex-1 w-full flex flex-row items-center text-[20px] font-medium leading-normal'>
                                                <FaPlaneDeparture size={20} color='#000'/>
                                                &nbsp;{e.Route.OriginAirport}&nbsp;
                                                <div className='flex-1 h-0 w-full border-b-2 border-b[#EEE] border-dashed'/>
                                            </div>
                                            <FaPlane size={20} color='#000'/>
                                            <div className='flex-1 w-full flex flex-row items-center text-[20px] font-medium leading-normal'>
                                                <div className='flex-1 h-0 w-full border-b-2 border-b[#EEE] border-dashed'/>
                                                &nbsp;{e.Route.DestinationAirport}&nbsp;
                                                <FaPlaneArrival size={20} color='#000'/>
                                            </div>
                                        </div>

                                        <div className={`h-fit w-full grid ${(searchDetails.class === 'all') ? 'grid-cols-2' : 'grid-cols-1'} md:grid-cols-1`}>
                                            <div className={`h-fit w-full ${(searchDetails.class === 'Business') ? 'hidden' : 'flex'} flex-col items-start gap-y-[5px]`}>
                                                <div className='w-full flex items-center justify-center text-center text-sm text-green-600 font-semibold border-y border-y-[#EEE]'>
                                                    Economy
                                                </div>
                                                <div className='w-full flex flex-row flex-wrap items-center text-center gap-x-[5px] text-sm font-medium leading-normal'>
                                                    <BiSolidPlaneAlt size={14} color="#888"/>
                                                    <div className='text-sm text-[#888] font-medium'>Air:</div>
                                                    <div>{e.YAirlines}</div>
                                                    {e.YAvailable ? (
                                                        <>
                                                            <div className={`${e.YDirect ? 'bg-green-600' : 'bg-[#FF7F7F]'} text-white text-sm rounded-md px-[5px]`}>{e.YDirect ? 'Direct' : 'Indirect'}</div>
                                                            {Number(e.YRemainingSeats) === 0 ? (<></>) : (
                                                                <div className='font-medium bg-[#888] text-white text-sm rounded-md px-[5px]'>{e.YRemainingSeats} seat(s) left</div>
                                                            ) }
                                                        </>
                                                    ) : (
                                                        <div className='bg-[#888] w-fit px-[5px] rounded-md text-white text-sm'>Not Available</div>
                                                    )}
                                                </div>
                                                <div className='w-full flex flex-row flex-wrap items-center text-center gap-x-[5px] text-sm font-medium leading-normal'>
                                                    <FaRegClock size={14} color="#888"/>
                                                    <div className='text-sm text-[#888] font-medium'>Last Checked:</div>
                                                    <div className='text-sm font-normal text-black'>{moment(e.UpdatedAt).fromNow()}</div>
                                                </div>
                                                <div className='w-full flex flex-row flex-wrap items-center text-center gap-x-[5px] text-sm font-medium leading-normal'>
                                                    <FaMoneyBill size={14} color="#888"/>
                                                    <div className='text-sm text-[#888] font-medium'>Cost:</div>
                                                    <div className='text-sm font-normal text-black'>{e.YAvailable ? Number(e.YMileageCost).toLocaleString()+' pts' : '-'}</div>
                                                </div>
                                                <div className='w-full flex flex-row flex-wrap items-center text-center gap-x-[5px] text-sm font-medium leading-normal'>
                                                    <FaRegMoneyBillAlt size={14} color="#888"/>
                                                    <div className='text-sm text-[#888] font-medium'>EstimatedCost:</div>
                                                    <div className='text-sm font-normal text-black'>{e.YAvailable ? '$'+Number((e.YMileageCost*0.03).toFixed(0)).toLocaleString() : '-'}</div>
                                                </div>
                                            </div>

                                            <div className={`h-fit w-full ${(searchDetails.class === 'Economy') ? 'hidden' : 'flex'} flex-col items-start gap-y-[5px]`}>
                                                <div className='w-full flex items-center justify-center text-center text-sm text-purple-600 font-semibold border-y border-y-[#EEE]'>
                                                    Business
                                                </div>
                                                <div className='w-full flex flex-row flex-wrap items-center text-center gap-x-[5px] text-sm font-medium leading-normal'>
                                                    <BiSolidPlaneAlt size={14} color="#888"/>
                                                    <div className='text-sm text-[#888] font-medium'>Air:</div>
                                                    <div>{e.JAirlines}</div>
                                                    {e.JAvailable ? (
                                                        <>
                                                            <div className={`${e.JDirect ? 'bg-green-600' : 'bg-[#FF7F7F]'} text-white text-sm rounded-md px-[5px]`}>{e.JDirect ? 'Direct' : 'Indirect'}</div>
                                                            {Number(e.JRemainingSeats) === 0 ? (<></>) : (
                                                                <div className='font-medium bg-[#888] text-white text-sm rounded-md px-[5px]'>{e.JRemainingSeats} seat(s) left</div>
                                                            ) }
                                                        </>
                                                    ) : (
                                                        <div className='bg-[#888] w-fit px-[5px] rounded-md text-white text-sm'>Not Available</div>
                                                    )}
                                                </div>
                                                <div className='w-full flex flex-row flex-wrap items-center text-center gap-x-[5px] text-sm font-medium leading-normal'>
                                                    <FaRegClock size={14} color="#888"/>
                                                    <div className='text-sm text-[#888] font-medium'>Last Checked:</div>
                                                    <div className='text-sm font-normal text-black'>{moment(e.UpdatedAt).fromNow()}</div>
                                                </div>
                                                <div className='w-full flex flex-row flex-wrap items-center text-center gap-x-[5px] text-sm font-medium leading-normal'>
                                                    <FaMoneyBill size={14} color="#888"/>
                                                    <div className='text-sm text-[#888] font-medium'>Cost:</div>
                                                    <div className='text-sm font-normal text-black'>{e.JAvailable ? Number(e.JMileageCost).toLocaleString()+' pts' : '-'}</div>
                                                </div>
                                                <div className='w-full flex flex-row flex-wrap items-center text-center gap-x-[5px] text-sm font-medium leading-normal'>
                                                    <FaRegMoneyBillAlt size={14} color="#888"/>
                                                    <div className='text-sm text-[#888] font-medium'>EstimatedCost:</div>
                                                    <div className='text-sm font-normal text-black'>{e.JAvailable ? '$'+Number((e.JMileageCost*0.03).toFixed(0)).toLocaleString() : '-'}</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* {(searchDetails.origin.length === 1 && searchDetails.destination.length === 1 && (searchDetails.class !== 'Economy')) ? (
                                            <div className={`${(e.JAvailable && (flightsPrice.BUSINESS > 0)) ? 'bg-blue-600 cursor-pointer' : 'bg-[#AAA] cursor-default pointer-events-none'} text-white h-[40px] w-full font-medium rounded-md px-[5px] flex items-center justify-center gap-[5px] duration-200 hover:opacity-50`} onClick={() => handleBookURL(e)}>
                                                {(e.JAvailable && (flightsPrice.BUSINESS > 0)) ? 'Claim $'+Number((flightsPrice.BUSINESS - (e.JMileageCost*0.03)).toFixed(0)).toLocaleString() : '-'} {flightsPrice.loading ? (<div className='h-[20px] w-[20px] rounded-full border-2 border-black border-t-white animate-spin'></div>) : (<></>)}
                                            </div>
                                        ) : (<></>)} */}
                                    </div>
                                ))}
                            </div>
                        )
                    )}
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Search