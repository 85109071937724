import React, { useState, useEffect } from 'react'
import { ImCross } from 'react-icons/im'
import { FaHandPointRight, FaHandPointLeft } from 'react-icons/fa'

import fmfLogo from '../assets/fmfLogo.png'
import googleDownloadBlack from '../assets/googleDownloadBlack.png'

const AppDownload = ({ display, setDisplay }) => {
    const [topValue, setTopValue] = useState('top-[-100%]')

    useEffect(() => {
        if (display.downloadApp) {
            setTopValue('top-[60px]')
        }
    }, [display.downloadApp])
    
    const handleClick = () => {
        setTopValue('top-[-100%]')
        setDisplay(p => {return{...p, downloadApp: false}})
    }

    return (
        <div className={`fixed ${topValue} ${display.downloadApp ? 'opacity-100' : 'opacity-0'} z-20 h-[calc(100%-60px)] w-full flex justify-center bg-white transition-all duration-1000 ease-in-out appDownloadPage:overflow-y-scroll`}>
            <div className='relative h-full w-full max-w-[1700px] p-[20px] flex flex-col items-center justify-center appDownloadPage:h-fit'>
                <img src={fmfLogo} alt="logo" className='w-full max-w-[100px] rounded-xl shadow-[0px_0px_5px_0px] shadow-[#CCC]' />
                <div className='absolute top-[20px] left-[20px] h-fit w-fit cursor-pointer hover:opacity-50 duration-200' onClick={() => handleClick()}>
                    <ImCross size={30} color='#AAA'/>
                </div>
                <p className='leading-normal text-black text-[36px] text-center font-semibold md:text-[24px]'>Wanna become a PRO?</p>
                <p className='leading-normal text-gray-600 text-[16px] text-center md:text-[14px]'>We have you covered. Download and use our mobile app to be a PRO.</p>
                <div className='bg-blue-100 p-[20px] rounded-xl my-[20px]'>
                    <p className='font-semibold leading-normal'>Why mobile app?</p>
                    <ul className='list-disc list-inside'>
                        <li className='leading-normal'>Get full access to all sources.</li>
                        <li className='leading-normal'>Faster search time.</li>
                        <li className='leading-normal'>More search results.</li>
                        <li className='leading-normal'>Better user experience.</li>
                    </ul>
                </div>
                <div className='w-full max-w-[300px] grid grid-flow-col items-center gap-2'>
                    <FaHandPointRight size={40} color='#AAA' />
                    <a href='https://play.google.com/store/apps/details?id=com.betelgues.fmf' target='_blank' className='rounded-md duration-200 cursor-pointer hover:shadow-[0px_0px_10px_5px_#AAA]'>
                        <img src={googleDownloadBlack} alt="logo" className='w-full' />
                    </a>
                    <FaHandPointLeft size={40} color='#AAA'/>
                </div>
            </div>
        </div>
    )
}

export default AppDownload