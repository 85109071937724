import axios from "axios";
import moment from "moment";
import { host } from "../host";

export const getFlightsPrice = async ({ searchDetails, setFlightsPrice }) => {
    setFlightsPrice({ loading: true, BUSINESS: 0 })
    const cancelToken = axios.CancelToken.source()
    const url = `${host}/api/v1/seats/search_flight_price`;
    
    try {
        const response = await axios.get(url, {
            timeout: 60000,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Content-Type": "application/json",
            },
            params: { origin: searchDetails.origin[0], destination: searchDetails.destination[0], from: moment(searchDetails.from).add(1, 'day').format('YYYY-MM-DD') }
        },
        {
            cancelToken: cancelToken.token
        })
        const obj = response.data
        console.log(obj);
        
        if (obj.success) {
            let getCurrency = await axios.get(`https://api.exchangerate-api.com/v4/latest/${obj.result[0].currency}`)
            getCurrency = getCurrency.data.rates.USD
            setFlightsPrice(p => { return { ...p, loading: false, BUSINESS: obj.result[0].totalPrice*getCurrency } })
        } else {
            setFlightsPrice(p => { return { ...p, loading: false, BUSINESS: 0 } })
        }
    
    } catch (err) {
          if (axios.isCancel(err)) {
            console.log('Seats fetch cancelled!');
        } else {
            console.log(err);
        }
        setFlightsPrice(p => { return { ...p, loading: false, BUSINESS: 0 } })
    }
}